@import "./colors";
$bg-white: white;



.menu {
    position: fixed;
    background-color: $primary;
    left: -230px;
    right: 0;
    top: 0;
    padding-top: 30px;
    width: 230px;
    bottom: 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, .1), 0 0 15px rgba(0, 0, 0, .08);
    transition: left .6s;
    z-index: 20;


    .menu-content {
        position: relative;
        margin-top: 150px;
        overflow: hidden;
        color: $color-light;
        ul {
            list-style: none;
            padding-left: 0;

            li {
                margin: 1rem 0;
            }
        }
    }

    a {
        display: block;
        transition: all .2s;
        color: $color-light;
        padding: 10px 30px;
        cursor: pointer;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }


    a::after {
        content: '';
        display: block;
        width: 0px;
        height: 2px;
        background: $color-light;
        transition: 0.2s;
    }
    
    a:hover::after, a.active::after {
        width: 20%;
    }

}

.menu.menu-open{
    left: 0;
}