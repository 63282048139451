// Should be first line.
@import "./modules/theme";
@import "./modules/mixins";
@import "./modules/fonts";
@import "./modules/hamburgers/hamburgers";
@import "./modules/menu";
@import '../../_generated/_prebuilt/icons';

html,body {
    width: 100%;
    overflow-x: hidden;
}

// Common Items
a:hover {
    text-decoration: none !important;
}


h1,
h2 {
    font-family: 'Brandon Grotesque', sans-serif;
    font-weight: bold;
}

h5,
h6 {
    font-weight: 400;
}

.w-15 {
    width: 15% !important;
}

.small {
    font-size: 0.9rem;
}

#menu-toggle{
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
    -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
    outline: none !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


a.nav-link, a.menu-link {
    font-family: 'Brandon Grotesque', sans-serif !important;
    font-size: 0.9rem;
    font-weight: 300;
}

a.nav-link.active, a.menu-link.active {
    font-weight: 500;
}

a.nav-link:last-child {
    padding-right: 0;
}

a.blog-link::before {
    content: "\f104";
    margin-left: -12px;
    padding-right: 5px;
    font: normal normal normal 14px/1 FontAwesome;
}

.sticky-overlay {
    z-index: 1500;
}

header {
    background-color: transparent;
    right: 0;
    left: 0;
    position:fixed;
   
    
}

.fixed-header {
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
}

// easy scroll

#scroll {
    position: fixed;
    right: 20px;
    bottom: 20px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: $primary;
    display: none;
    border-radius: 50%;
    border: 1px solid $secondary;
    z-index: 1050;
}

#scroll span {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -8px;
    margin-top: -12px;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-bottom-color: $color-light;
}

#scroll:hover {
    background-color: $secondary;
}

// Mail Bar

.mail-bar {
    div.container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        vertical-align: baseline;

        strong {
            font-family: 'Brandon Grotesque', sans-serif;
            font-size: 1rem;
            padding-right: 5px;
        }

        span {
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            font-size: 0.9rem;
            padding-left: 5px;
        }
    }

    height: 60px;
    height: 5.5vh;
    background-color: $primary;
    color: $color-light;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
    font-size: 12px;
    padding: 10px 0;
}

.copy-right-text {
    font-weight: 300;
    font-size: 0.7rem;
    color: $color-light;
}

.font-medium {
    font-weight: 500;
}

.intro-container {
    width: 100%;
    height: 85.83vh;
    position: relative;
    align-items: center;

    .main-content {
        margin-top: 5.5vh;
    }

    .intro-cta {
        padding: 10px 15px;
        margin: 1rem 0;
    }

    .intro-text {
        font-family: 'Aqua Grotesque', sans-serif
    }

    .slider-control {
        width: 100%;
        transform: translateY(-1.5rem);

        a.btn-slider-control {
            font-size: 0.9rem;
            line-height: 2rem;
            color: $color-light;
            transition: padding 300ms ease-in;
        }

        a.btn-disabled {
            padding-left: 0.25rem;
            padding-right: 0.25rem;
            background-color: $secondary;
            border-color: $secondary;
        }
    }

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }



    #carousel {
        height: 100%;
        width: 100%;

        .slick-list {
            height: 100%;

            .slick-track {
                height: 100%;

                .slick-slide {
                    padding: 0 !important;

                    div {
                        height: 100%;

                        .slide {
                            height: 100%;
                        }
                    }
                }
            }
        }

        .slide {
            width: 100%;
            height: 100%;

            div {
                width: 100%;
                height: 100%;
                background-attachment: fixed;
                background-position: top center;
                background-repeat: no-repeat;
                background-size: cover;
            }

        }
    }

}


.icon-container {
    width: 40px;
}

.link-container {
    width: 20px;
}


.about-container {
    margin-bottom: 3vh;
    padding-top: 3rem;

    .side-element::before {
        content: '';
        left: 0;
        height: 70%;
        position: absolute;
        width: 40px;
        background-color:#F5F5F5 !important;
    }

    #contact-form {
        transform: translateY(calc(-5.5vh - 3rem))
    }

    #contact-form-header {
        height: 11vh;
    }

    .contact-form {
        .form-control {
            padding: 1.5rem;
            font-size: 0.9rem;
        }
    }

    #contact-form-container {
        transform: translateZ(0);
    }

}

/*Sid css start*/

.side-text {
    -ms-transform: rotate(-90deg);
    /* IE 9 */
    -webkit-transform: rotate(-90deg);
    /* Safari 3-8 */
    transform: rotate(-90deg);
}

.h-300 {
    height: 300px;
}

.big {
    font-size: 40px;
}


/*menu css start*/

.navbar {
    background: $color-dark;
}

.nav-item::after {
    content: '';
    display: block;
    width: 0px;
    height: 2px;
    background: $color-light;
    transition: 0.2s;
}

.nav-item:hover::after {
    width: 50%;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: $color-light;
}

.nav-link {
    padding: 7px 0px !important;
    transition: 0.2s;
}


/*card css start*/

.cad {
    display: inline-block;
    border: 0;
    position: relative;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1);
    transition: all 200ms ease-in;
    transform: scale(1);
    min-height: 300px;
    text-align: center;

}

.cad:hover {
    background: $secondary;
    color: $color-light;
    box-shadow: 10px 50px 90px 0.5 $color-dark;
    z-index: 2;
    -webkit-transition: all 200ms ease-in;
    -webkit-transform: scale(1.1);
    -ms-transition: all 200ms ease-in;
    -ms-transform: scale(1.1);
    -moz-transition: all 200ms ease-in;
    -moz-transform: scale(1.1);
    transition: all 200ms ease-in;
    transform: scale(1.1);
}

/*End*/

.consulting-container {
    // height: 70vh;
    background-image: url("../img/consultancy-bg.png");
    background-size: cover;
    background-position: center center;
    margin-bottom: 50px;
    color: $color-light;
}

.side-bar {
    width: 60px;
    margin-right: -60px;
}



.customer {
    .slick-slide {
        padding: 0.5rem;

        img {
            margin: 0 auto;
        }
    }
}

section#clients, section#client-testimonial, section#map-section {
    margin: 3rem 0;
}

.client {
    .slick-slide {
        padding: 0.5rem;
    }
}

.testimonial-image {
    height: 50px;
    width: 50px !important;
}



/* Blog Style */
.blog-latest-container {
    height: 600px;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
}


.blog-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
}

ul.blog-category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    text-align: center;

    li {
        list-style: none;
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-content: center;
        line-height: 100%;
        padding: 10px;
        cursor: pointer;
    }

    li:hover {
        background-color: $color-light;;
        transition: background-color 0.3s linear;
    }

    li.active {
        background-color: $color-light;
    }
}

.blog-overlay {
    transform: scale(1);
    transition: all 0.5s ease;
}

.card:hover .blog-overlay {
    transform: scale(1.05);
}

.arrow-nav-link {
    color: $color-light;
    display: block;
    padding: 7px 0px !important;
}

.social-icon {
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    .fa {
        height: 16px !important;
        width: 16px !important;
    }
}
.social-icon:hover {
    color: $primary;
}

.icon-stack-horizontal{
    margin: 0.25rem 0.5rem;
}
.icon-stack-vertical{
    margin: 0.5rem 0.25rem;
}

.footer-container {
    height: 200px;
    height: 30vh;

    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}

// Add Responsive SCSS
@import "./modules/responsive";