@import "mixins";

@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_blk",900, normal, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_bld",700, normal, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_med",600, normal, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_reg",400, normal, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_light",300, normal, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_thin",100, normal, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_blk_it",900, italic, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_bld_it",700, italic, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_med_it",600, italic, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_reg_it",400, italic, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_light_it",300, italic, otf);
@include font-face("Brandon Grotesque", "../fonts/brandon_grotesque/Brandon_thin_it",100, italic, otf);


@include font-face("Montserrat", "../fonts/montserrat/montserrat-black-webfont", 900, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-extrabold-webfont", 800, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-bold-webfont", 700, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-semibold-webfont", 600, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-medium-webfont", 500, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-light-webfont", 400, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-extralight-webfont", 200, normal, woff woff2);
@include font-face("Montserrat", "../fonts/montserrat/montserrat-thin-webfont", 100, normal, woff woff2);


@include font-face("Muli", "../fonts/muli/Muli-Black", 900, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-BlackItalic", 900, italic, ttf);
@include font-face("Muli", "../fonts/muli/Muli-ExtraBold", 800, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-ExtraBoldItalic", 800, italic, ttf);
@include font-face("Muli", "../fonts/muli/Muli-Bold", 700, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-BoldItalic", 700, italic, ttf);
@include font-face("Muli", "../fonts/muli/Muli-SemiBold", 600, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-SemiBoldItalic", 600, italic, ttf);
@include font-face("Muli", "../fonts/muli/Muli-Regular", 500, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-Italic", 500, italic, ttf);
@include font-face("Muli", "../fonts/muli/Muli-Light", 400, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-LightItalic", 400, italic, ttf);
@include font-face("Muli", "../fonts/muli/Muli-ExtraLight", 200, normal, ttf);
@include font-face("Muli", "../fonts/muli/Muli-ExtraLightItalic", 200, italic, ttf);


@include font-face("Aqua Grotesque", "../fonts/aqua_grotesque/aqua",400, normal, ttf otf eot woff woff2);