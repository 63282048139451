@import "./colors";
@import "./variables";
@import "./fonts";

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary
);
$font-family-sans-serif: "Muli", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

@import "../../vendor/bootstrap/scss/bootstrap";